var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.objChild > 0)?_c('a',{staticClass:"btn btn-primary btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.getUserChild1}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-users"})])]}}],null,false,2839956665)},[_c('span',[_vm._v("Drill Down Users")])])],1):( _vm.userp.role_id == '61829dc7e64b1b17688326d5' ||
      _vm.userp.role_id == '61829dd1e64b1b17688326df' ||
      _vm.userp.role_id == '61829de6e64b1b17688326f3' ||
      _vm.userp.role_id == '6424071f50b77c6cf890ab0d')?_c('a',{staticClass:"btn btn-primary disabled btn-action mr-1",attrs:{"disabled":"","hidden":""}},[_c('i',{staticClass:"fas fa-users"})]):_c('a',{staticClass:"btn btn-primary disabled btn-action mr-1",attrs:{"disabled":""}},[_c('i',{staticClass:"fas fa-users"})]),(_vm.checkUserPermission('CHANGE-PASSWORD'))?_c('a',{staticClass:"btn btn-info btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.onDialogChangePassword}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-user-cog"})])]}}],null,false,1755791052)},[_c('span',[_vm._v("Change Password")])])],1):_vm._e(),(_vm.checkUserPermission('EDIT'))?_c('a',{staticClass:"btn btn-warning btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.updateUser}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"far fa-edit"})])]}}],null,false,1727570758)},[_c('span',[_vm._v("Edit User")])])],1):_vm._e(),(
      _vm.item.role_id !='6191dc644825e6f57ffa231f' && _vm.item.role_id !='6191dce94825e65937fa2340' &&(
      _vm.userp.role_id == '61829dc7e64b1b17688326d5' ||
      _vm.userp.role_id == '61829dd1e64b1b17688326df' ||
      _vm.userp.role_id == '61829de6e64b1b17688326f3' ||
      _vm.userp.role_id == '6424071f50b77c6cf890ab0d' )
    )?_c('a',{staticClass:"btn btn-success btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.upConfigUser}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-cogs"})])]}}],null,false,87581923)},[_c('span',[_vm._v("Limmit Betting")])])],1):_vm._e(),(
      _vm.checkUserPermission('EDIT') &&
      _vm.userp.role_id != '611532b19c6e77ad0fd50c1c' &&
      _vm.userp.role_id != '61829dc7e64b1b17688326d5' &&
      _vm.userp.role_id != '61829dd1e64b1b17688326df' &&
      _vm.userp.role_id != '61829de6e64b1b17688326f3' &&
      _vm.userp.role_id != '61bf5eb0c73d7eea8634fbf4' &&
      _vm.userp.role_id != '6424071f50b77c6cf890ab0d'
    )?_c('a',{staticClass:"btn btn-primary btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.getAllLimitBetting}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-cogs"})])]}}],null,false,87581923)},[_c('span',[_vm._v("Limmit Betting")])])],1):_vm._e(),(
      _vm.item.role_id !='6191dc644825e6f57ffa231f' && _vm.item.role_id !='6191dce94825e65937fa2340' &&(
      _vm.userp.role_id == '61829dc7e64b1b17688326d5' ||
      _vm.userp.role_id == '61829dd1e64b1b17688326df' ||
      _vm.userp.role_id == '61829de6e64b1b17688326f3' ||
      _vm.userp.role_id == '6424071f50b77c6cf890ab0d' ) 
    )?_c('a',{staticClass:"btn btn-warning btn-action mr-1",attrs:{"disabled":_vm.dataDelete.length > 0},on:{"click":_vm.upConfigSlotLimit}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-cogs"})])]}}],null,false,87581923)},[_c('span',[_vm._v("Limmit Slot")])])],1):_vm._e(),(_vm.checkUserPermission('EDIT') && _vm.userp.role_id == '611532b19c6e77ad0fd50c1c' )?_c('v-menu',{staticClass:"mx-0 px-0",attrs:{"offset-y":"","disabled":_vm.dataDelete.length > 0},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn btn-info btn-action mr-1",staticStyle:{"min-width":"0px"},attrs:{"text":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('i',{staticClass:"fas fa-cogs"})])]}}],null,true)},[_c('span',[_vm._v("Limmit Betting")])])],1)]}}],null,false,2640410807)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":item.value},on:{"click":function($event){return _vm.updateCompanyLimitBetting(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_vm._e(),_c('dialog-change-password',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogChangePassword,"is-validate":_vm.validPassword,"user":_vm.user,"loading":_vm.loading},on:{"submit":_vm.onChangePassword,"onCloseDialog":_vm.closeDialogChangePassword}}),(
      _vm.userp.role_id == '61829dc7e64b1b17688326d5' ||
      _vm.userp.role_id == '61829dd1e64b1b17688326df' ||
      _vm.userp.role_id == '61829de6e64b1b17688326f3' ||
      _vm.userp.role_id == '6424071f50b77c6cf890ab0d' 
    )?_c('config-user-data',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigUserData,"is-validate":_vm.validPassword,"dataConfig":_vm.configUserData,"loading":_vm.loading,"limitConfig":_vm.limitConfig,"user":_vm.item},on:{"submit":_vm.onChangeConfigUser,"onCloseDialog":_vm.closeConfigUserDialog,"changeTab":_vm.onChangeTab}}):_vm._e(),(
      _vm.userp.role_id == '61829dc7e64b1b17688326d5' ||
      _vm.userp.role_id == '61829dd1e64b1b17688326df' ||
      _vm.userp.role_id == '61829de6e64b1b17688326f3' ||
      _vm.userp.role_id == '6424071f50b77c6cf890ab0d' 
    )?_c('config-slot-limit-data',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigSlotLimitData,"dataConfig":_vm.configSlotLimitData,"loading":_vm.loading,"user":_vm.item},on:{"submit":_vm.onChangeConfigSlotLimit,"onCloseDialog":_vm.closeConfigSlotLimitDialog}}):_vm._e(),_c('config-high-level-user',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigHighLevelUser,"is-validate":_vm.validPassword,"dataConfig":_vm.limitHighLevelUser,"limitConfig":_vm.limitConfig,"loading":_vm.loading,"userp":_vm.userp,"user":_vm.item},on:{"submit":_vm.onChangeConfigHighLevelUser,"onCloseDialog":_vm.closeConfigHighLevelUserDialog,"changeTab":_vm.onChangeTab}}),_c('config-high-level-user-company-usd',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigHighLevelUserCompanyUsd,"dataConfig":_vm.configHighLevelUserCompanyUsd,"loading":_vm.loading},on:{"submit":_vm.onChangeConfigHighLevelUserCompanyUsd,"onCloseDialog":_vm.closeConfigHighLevelUserCompanyUsdDialog}}),_c('config-high-level-user-company-kh',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigHighLevelUserCompanyKh,"dataConfig":_vm.configHighLevelUserCompanyKh,"loading":_vm.loading},on:{"submit":_vm.onChangeConfigHighLevelUserCompanyKh,"onCloseDialog":_vm.closeConfigHighLevelUserCompanyKhDialog}}),_c('config-high-level-user-company-vn',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogConfigHighLevelUserCompanyVn,"dataConfig":_vm.configHighLevelUserCompanyVn,"loading":_vm.loading},on:{"submit":_vm.onChangeConfigHighLevelUserCompanyVn,"onCloseDialog":_vm.closeConfigHighLevelUserCompanyVnDialog}}),_c('user-list1',{attrs:{"darkmode":_vm.darkmode,"dialog":_vm.dialogListUser,"users":_vm.userChild,"data-request":_vm.data1,"userp":_vm.userp},on:{"getData1":_vm.getUserChild1,"onCloseDialog":function($event){_vm.dialogListUser = !_vm.dialogListUser}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }